/* eslint-disable no-undef */
import axios from 'axios'
import DataHandlerService, { dataHandler } from './data'
import config from 'src/config'

const data = new DataHandlerService()

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
// create a new axios instance
const instance = axios.create({
  baseURL: config.API_ROOT,
})

// set request token
instance.defaults.headers.common.Authorization =
  'Bearer ' + localStorage.getItem('niwajwt')

// response handlers
const errorHandler = (error): Promise<any> => {
  const response = {
    message:
      'A network error occured. Please check your internet connection and try again.',
  }
  if (error['response']) {
    // set token age to outdated time
    if (error['response']['status'] === 401) {
      dataHandler.setTokenAge(0)
    }
    response['code'] = error['response']['status']
    response['status'] = error['response']['statusText']
    response['message'] = Object.keys(error['response']['data'])
      .map((x) => error['response']['data'][x])
      .join('\n ')
    console.log(error['response']['data'])
  }
  return Promise.reject(response)
}

const successHandler = (response): any => {
  // set next token ttl in mins
  dataHandler.setTokenAge(parseInt(process.env.REACT_APP_TOKEN_TTL))

  const output = {}
  if (response) {
    output['code'] = response['status']
    output['status'] = response['statusText']
    output['data'] = response['data']
  }
  return Promise.resolve(output)
}

// before a request is made
instance.interceptors.request.use((_config) => {
  if (data.getUserSession() !== null) {
    _config.headers = data.setHeader()
  }
  _config.withCredentials = false
  // start the ng-progress
  return _config
})

// before a response is returned stop nprogress
instance.interceptors.response.use(
  (response) => successHandler(response),
  (error) => errorHandler(error)
)

export default instance

export const GenericHTTP = async function (
  url: string,
  body: any = null,
  headers: any = {},
  options: any = {},
  isJsonp: boolean
): Promise<any> {
  // api_call is a promise
  const api_call = new Promise((resolve, reject) => {
    fetch(url, {
      method: 'POST',
      mode: 'no-cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: body,
      ...options,
    })
      .then(async (response) => {
        if (!response.ok) {
          reject({ message: `Network request failed.` })
          return
        }

        if (isJsonp) {
          const response_text = await response.text()
          const match = response_text.substring(
            response_text.indexOf('(') + 1,
            response_text.lastIndexOf(')')
          )

          if (!match) {
            // throw new Error('Invalid JSONP response');
            resolve(JSON.parse(response_text))
          }

          resolve(JSON.parse(match))
        } else {
          try {
            resolve(response.json())
          } catch (e) {
            console.log('the error and response', e, response, response.body)
            resolve(response.body)
          }
        }
      })
      .catch((error) => {
        reject(error)
      })
  })
  return await api_call
}
