import React from 'react'
import { Link } from 'react-router-dom'
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js'
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap'
import { AuthContext } from 'src/store/auth/Store'

import logo from 'src/assets/img/brand/logo.png'
import logoName from 'src/assets/img/brand/logo_name.png'

const PublicNavbar: React.FC = () => {
  React.useEffect(() => {
    const headroom = new Headroom(document.getElementById('navbar-main'))
    // initialise
    headroom.init()
  }, [])

  const [authState] = React.useContext(AuthContext)

  const [collapseClasses, setCollapseClasses] = React.useState('')

  const onExiting = (): void => {
    setCollapseClasses('collapsing-out')
  }

  const onExited = (): void => {
    setCollapseClasses('')
  }

  return (
    <>
      <header className="header-global">
        <Navbar
          className="navbar-main navbar-transparent navbar-light headroom"
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <img alt="" src={logo} className="logo" />
              <img
                alt=""
                src={logoName}
                className="d-none d-lg-inline-block"
                style={{ height: 38, width: 'auto' }}
              />
              <span className="d-lg-none">NIWA</span>
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
              className={collapseClasses}
              onExiting={onExiting}
              onExited={onExited}
            >
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Link to="/">NIWA</Link>
                  </Col>
                  <Col className="collapse-close" xs="6">
                    <button className="navbar-toggler" id="navbar_global">
                      <span />
                      <span />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="align-items-lg-center ml-lg-auto" navbar>
                <NavItem>
                  <NavLink to="/" tag={Link}>
                    Home
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>Help</NavLink>
                </NavItem>
                {!authState.token ? (
                  <>
                    <NavItem className="d-lg-none">
                      <NavLink to="/auth/login" tag={Link}>
                        Login
                      </NavLink>
                    </NavItem>
                    <NavItem className="d-lg-none">
                      <NavLink to="/auth/register" tag={Link}>
                        Create account
                      </NavLink>
                    </NavItem>
                    <NavItem className="d-none d-lg-block ml-lg-4">
                      <Link
                        className="btn btn-outline-neutral btn-icon btn-sm"
                        color="default"
                        to="/auth/login"
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-user fa-2x mr-1" />
                        </span>
                        <span className="nav-link-inner--text ml-1">Login</span>
                      </Link>
                    </NavItem>
                    <NavItem className="d-none d-lg-block ml-lg-4">
                      <Link
                        className="btn btn-outline-neutral btn-icon btn-sm"
                        color="default"
                        to="/auth/register"
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-user fa-2x mr-1" />
                        </span>
                        <span className="nav-link-inner--text ml-1">
                          Create Account
                        </span>
                      </Link>
                    </NavItem>
                  </>
                ) : (
                  <>
                    <NavItem className="d-lg-none">
                      {authState?.token &&
                      ['users'].includes(authState.user?.roles) ? (
                        <NavLink to="/admin" tag={Link}>
                          Dashboard
                        </NavLink>
                      ) : (
                        <NavLink to="/user" tag={Link}>
                          My Account
                        </NavLink>
                      )}
                    </NavItem>

                    <NavItem className="d-none d-lg-block ml-lg-4">
                      <Link
                        className="btn btn-outline-neutral btn-icon btn-sm"
                        color="default"
                        to={
                          authState?.token &&
                          ['users'].includes(authState.user?.roles)
                            ? '/user'
                            : '/admin'
                        }
                      >
                        <span className="btn-inner--icon">
                          <i className="fa fa-user fa-2x mr-1" />
                        </span>
                        <span className="nav-link-inner--text ml-1">
                          {authState?.token &&
                          ['users'].includes(authState.user?.roles)
                            ? 'My Account'
                            : 'Dashboard'}
                        </span>
                      </Link>
                    </NavItem>
                  </>
                )}
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    </>
  )
}

export default PublicNavbar
