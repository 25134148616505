import React, { createContext, useReducer } from 'react'
import Reducer from './Reducer'
import DataHandlerService from 'src/services/data'
import AuthService from 'src/services/auth'

export interface IAuthContextState {
  isLoading: boolean
  token: string | null
  user: any
}
export interface IAuthContextActions {
  restoreSession: (data: any) => void
  signIn: (data: any) => void
  signOut: () => void
  updateUserData: (data: any) => void
  getData: IAuthContextState
}

const dataHandler = new DataHandlerService()
const authService = new AuthService()

const initialState: IAuthContextState = {
  isLoading: true,
  token: dataHandler.getUserSession(),
  user: dataHandler.getUserData(),
}

const AuthStore: React.FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  const actions = {
    restoreSession: async (data) => {
      authService.setSession(
        {
          access_token: data.token,
          user: data.user.data,
          roles: data.user.roles,
          status: data.user.status,
        },
        false
      )
      dispatch({
        type: 'SIGN_IN',
        token: data.token,
        user: data.user,
      })
    },
    signIn: async (data) => {
      authService.setSession(data, false)
      dispatch({
        type: 'SIGN_IN',
        token: data.access_token,
        user: {
          data: data.user,
          roles: data.roles,
          status: data.status,
        },
      })
    },
    signOut: async () => {
      authService.logout()
      dispatch({ type: 'SIGN_OUT' })
    },
    updateUserData: async (data) => {
      dataHandler.setUserData(data)
      dispatch({
        type: 'UPDATE_USER',
        user: data,
      })
    },
    getData: state,
  }

  return (
    <AuthContext.Provider value={[state, dispatch, actions]}>
      {children}
    </AuthContext.Provider>
  )
}

export const AuthContext = createContext<
  [IAuthContextState, React.Dispatch<IAuthContextState>, IAuthContextActions]
>([initialState, null, null])
export default AuthStore
