import http from './http'
import Base from './base'

export default class AccountService extends Base {
  constructor() {
    super('accounts')
  }

  create(input): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()

      form.append('email', input.email)
      form.append('last_name', input.last_name)
      form.append('first_name', input.first_name)
      form.append('other_name', input.other_name)
      form.append('phone', input.phone)
      form.append('street', input.street)
      form.append('state', input.state)
      form.append('lga', input.lga)
      form.append('roles', input.roles)
      form.append('area_office', input.area_office)
      form.append(
        'individual_or_company_name',
        input.individual_or_company_name
      )
      form.append(
        'individual_or_company_address',
        input.individual_or_company_address
      )
      form.append('password', input.password)

      http
        .post('accounts/', form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
