import React, { createContext, useReducer } from 'react'
import Reducer from './Reducer'
import InvoiceService from 'src/services/invoice'

const invoiceService = new InvoiceService()

export interface IInvoiceStoreState {
  isLoading: boolean
  count: number
  invoices: any[]
  invoices_by_user: any[]
}

export interface IInvoiceStoreActions {
  read_all: () => Promise<any>
  read: (id) => Promise<any>
  read_by_user: (user_id) => Promise<any>
}

const initialState: IInvoiceStoreState = {
  isLoading: true,
  count: 0,
  invoices: [],
  invoices_by_user: [],
}

const InvoiceStore: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  const actions = {
    read_all: async () => {
      return new Promise((resolve, reject) => {
        invoiceService
          .read_all()
          .then((res) => {
            if (res.code === 200) {
              dispatch({
                type: 'SET_ALL',
                payload: res.data,
              })
              resolve(res.data)
            } else {
              reject(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    read: async (id) => {
      return new Promise((resolve, reject) => {
        invoiceService
          .read(id)
          .then((res) => {
            if (res === 200) {
              dispatch({
                type: 'SET',
                payload: res.data,
              })
              resolve(res.data)
            } else {
              reject(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    read_by_user: async (user_id) => {
      return new Promise((resolve, reject) => {
        invoiceService
          .read_all()
          .then((res) => {
            if (res.code === 200) {
              const _output = res.data.filter((x) => x.created_by === user_id)
              dispatch({
                type: 'SET_BY_USER',
                payload: _output,
              })
              resolve(_output)
            } else {
              reject(new Error(res.message))
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
  }

  return (
    <InvoiceContext.Provider value={[state, dispatch, actions]}>
      {children}
    </InvoiceContext.Provider>
  )
}

export const InvoiceContext = createContext<
  [IInvoiceStoreState, React.Dispatch<IInvoiceStoreState>, IInvoiceStoreActions]
>([initialState, null, null])
export default InvoiceStore
