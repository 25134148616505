import React from 'react'
import { BrowserRouter } from 'react-router-dom'

// import "assets/vendor/nucleo/css/nucleo.css";
// import "assets/vendor/font-awesome/css/font-awesome.min.css";
// import "assets/scss/argon-design-system-react.scss?v1.1.0";
import 'react-toastify/dist/ReactToastify.min.css'

import AuthStore from 'src/store/auth/Store'
import ApplicationStore from 'src/store/application/Store'
import MainRouter from 'src/routes/Main'
import { ToastProvider } from 'react-toast-notifications'
// import ScrollToTop from "src/components/Utility/ScrollToTop";
import ScrollToTop from 'src/components/Utility/ScrollToTop'
import { ToastContainer } from 'react-toastify'
import InvoiceStore from 'src/store/payment/Store'
import AuthChecker from './components/Utility/AuthChecker'

// Alternate toast
// https://fkhadra.github.io/react-toastify/introduction

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <ToastContainer />
      <AuthStore>
        <AuthChecker />
        <ApplicationStore>
          <InvoiceStore>
            <ToastProvider>
              <MainRouter />
            </ToastProvider>
          </InvoiceStore>
        </ApplicationStore>
      </AuthStore>
    </BrowserRouter>
  )
}

export default App
