import React from 'react'
import { toast } from 'react-toastify'
import { dataHandler } from 'src/services/data'
import { AuthContext } from 'src/store/auth/Store'

/**
 * Watch for token validity
 */

export const AuthChecker = (): null => {
  const timer = React.useRef<any>(null)
  const [authState, , authAction] = React.useContext(AuthContext)

  const checkExpiry = (): void => {
    const ttl = dataHandler.getTokenAge()
    const now = Math.floor(new Date().getTime() / 1000)
    const timeLeft = ttl - now
    if (timeLeft <= 0) {
      if (authState.token) {
        toast.error(
          'You have been logged out due to inactivity. Please log in to continue.',
          { autoClose: false }
        )
        authAction.signOut()
      }
    }
  }

  React.useEffect(() => {
    if (authState.token) {
      if (!timer.current) {
        timer.current = setInterval(checkExpiry, 5000)
      }
    } else {
      timer.current && clearInterval(timer.current)
      timer.current = null
    }
    return () => {
      timer.current && clearInterval(timer.current)
      timer.current = null
    }
  }, [authState.token])

  return null
}

export default AuthChecker
