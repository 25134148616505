import * as _ from 'lodash'
import { IApplicationStoreState } from './Store'

const Reducer = (state, action): IApplicationStoreState => {
  switch (action.type) {
    case 'SET_ALL':
      return {
        ...state,
        isLoading: false,
        applications: action.payload,
        count: action.payload.length,
      }
    case 'SET':
      return {
        ...state,
        isLoading: false,
        applications:
          Array.from(state.applications) ||
          _.unionBy(Array.from(state.applications), [action.payload], 'id'),
      }
  }
}

export default Reducer
