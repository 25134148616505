import http from './http'
import Base from './base'

export default class ApplicationService extends Base {
  constructor() {
    super('application')
  }

  generate(input): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()

      form.append('invoice_id', input.invoice_id)
      form.append('uid', input.uid)
      form.append('area_office', '-')
      form.append('fullname', input.fullname)
      form.append('company', input.fullname)
      form.append('email', input.email)
      form.append('applicant', 'individual')
      form.append('state', '-')
      form.append('lga', '-')
      form.append('created_by', input?.created_by)

      http
        .post(`${this.model}/`, form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  generate_legacy(input): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()

      form.append('fullname', input?.fullname)
      form.append('company', input?.fullname)
      form.append('created_by', input?.created_by)

      form.append('applicant', input?.applicant)

      http
        .post(`${this.model}/`, form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  regenerate(input): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      for (const i in input) {
        form.append(`${i}`, input[i])
      }

      http
        .post(`${this.model}/`, form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
