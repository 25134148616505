import { unionBy } from 'lodash'
import { IInvoiceStoreState } from './Store'

const Reducer = (state, action): IInvoiceStoreState => {
  switch (action.type) {
    case 'SET_ALL':
      return {
        ...state,
        isLoading: false,
        invoices: action.payload,
        count: action.payload.length,
      }
    case 'SET_BY_USER':
      return {
        ...state,
        invoices: action.payload,
        count: action.payload.length,
        isLoading: false,
      }
    case 'SET':
      return {
        ...state,
        isLoading: false,
        invoices:
          Array.from(state.invoices) ||
          unionBy(Array.from(state.invoices), [action.payload], 'id'),
      }
  }
}

export default Reducer
