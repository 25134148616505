import { IAuthContextState } from './Store'

const Reducer = (state, action): IAuthContextState => {
  switch (action.type) {
    case 'RESTORE_TOKEN':
      return {
        ...state,
        isLoading: false,
        token: action.token,
        user: action.user,
      }
    case 'SIGN_IN':
      return {
        ...state,
        isLoading: false,
        token: action.token,
        user: action.user,
      }
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.user,
      }
    case 'SIGN_OUT':
      return {
        ...state,
        isLoading: false,
        token: null,
        user: null,
      }
  }
}

export default Reducer
