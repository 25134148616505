import http from './http'
import DataHandlerService from './data'

const dataHandler = new DataHandlerService()

export default class Base {
  constructor(model) {
    this.model = model
    this.user = dataHandler.getUserData()
  }

  model: string
  user: any

  read_all(): Promise<any> {
    return new Promise((resolve, reject) => {
      http
        .get(`${this.model}/`)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  read(id): Promise<any> {
    return new Promise((resolve, reject) => {
      http
        .get(`${this.model}/${id}/`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  update(input, id): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      for (const i in input) {
        form.append(`${i}`, input[i])
      }
      http
        .patch(`${this.model}/${id}/`, form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  delete(id): Promise<any> {
    return new Promise((resolve, reject) => {
      http
        .delete(`${this.model}/${id}/`)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
