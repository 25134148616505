import React, { Suspense, lazy, useContext } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'

import 'src/assets/vendor/nucleo/css/nucleo.css'
import 'src/assets/vendor/font-awesome/css/font-awesome.min.css'
import 'src/assets/scss/argon-design-system-react.scss?v1.1.0'

import Index from 'src/views/Index'
import { AuthContext } from 'src/store/auth/Store'
import SuspenseLoader from 'src/components/Commons/SuspenseLoader'

const Auth = lazy(() => import('src/views/auth/Index'))
const User = lazy(() => import('src/views/user/Index'))
const Admin = lazy(() => import('src/views/admin/Index'))
const ResetConfirm = lazy(() => import('src/views/auth/ResetConfirm'))

const MainRoute: React.FC = () => {
  const [authState] = useContext(AuthContext)
  const { pathname, search } = useLocation()

  return (
    <>
      <Suspense fallback={<SuspenseLoader />}>
        <Switch>
          <Route path="/" exact render={(props) => <Index {...props} />} />
          <Route
            path="/user"
            render={() =>
              authState.token ? (
                [
                  'admin',
                  'inspector',
                  'chief_inspector',
                  'area_manager',
                ].includes(authState.user?.roles) ? (
                  <Redirect to={'/admin'} />
                ) : (
                  <User />
                )
              ) : (
                <Redirect
                  push
                  to={{
                    pathname: '/auth/login',
                    search: `?return=${pathname + search}`,
                  }}
                />
              )
            }
          />
          <Route
            path="/admin"
            render={() =>
              authState.token ? (
                [
                  'admin',
                  'inspector',
                  'chief_inspector',
                  'area_manager',
                ].includes(authState.user?.roles) ? (
                  <Admin />
                ) : (
                  <Redirect to="/user" />
                )
              ) : (
                <Redirect
                  push
                  to={{
                    pathname: '/auth/login',
                    search: `?return=${pathname + search}`,
                  }}
                />
              )
            }
          />
          <Route
            path="/auth"
            render={() =>
              authState.token ? (
                [
                  'admin',
                  'inspector',
                  'chief_inspector',
                  'area_manager',
                ].includes(authState.user?.roles) ? (
                  <Redirect to="/admin" />
                ) : (
                  <Redirect to="/user" />
                )
              ) : (
                <Auth />
              )
            }
          />
          <Route path="/reset/:uid/:token" render={() => <ResetConfirm />} />
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </>
  )
}

export default MainRoute
