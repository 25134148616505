import http from './http'
import DataHandlerService from './data'
import AccountService from './account'
import Base from './base'

const dataHandler = new DataHandlerService()
const accountService = new AccountService()

export default class AuthService extends Base {
  constructor() {
    super('auth')
  }

  userProfile
  authenticated = this.isAuthenticated()

  validate(obj): boolean {
    if (
      obj.code === 200 &&
      obj.data !== null &&
      obj.data !== {} &&
      obj.data !== [] &&
      obj.data.length !== 0 &&
      Object.keys(obj.data).length !== 0
    ) {
      return true
    }
    return false
  }

  /* Auth0 methods begin */

  signup(input): Promise<any> {
    let form = new FormData()
    return new Promise((resolve, reject) => {
      form = new FormData()
      form.append('email', input.email)
      form.append('password1', input.password1)
      form.append('password2', input.password2)

      http
        .post(`${this.model}/registration/`, form)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  getCsrfToken(): string {
    function getCookie(name): string {
      let cookieValue = null
      if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';')
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim()
          // Does this cookie string begin with the name we want?
          if (cookie.substring(0, name.length + 1) === name + '=') {
            cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
            break
          }
        }
      }
      return cookieValue
    }
    const csrftoken = getCookie('csrftoken')
    return csrftoken
  }

  login(input): Promise<any> {
    let form = new FormData()
    return new Promise((resolve, reject) => {
      form = new FormData()
      form.append('email', input.email)
      form.append('password', input.password)
      http
        .post(`${this.model}/login/`, form)
        .then((response: any) => {
          if (response?.code === 200) {
            localStorage.setItem('niwajwt', response.data.access_token)
            localStorage.setItem('csrftoken', this.getCsrfToken())
            accountService
              .read(response.data.user.id)
              .then((res) => {
                response.data.user = { ...response.data.user, ...res.data }
                resolve(response)
              })
              .catch((err) => {
                reject(err)
              })
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  login_new(input): Promise<any> {
    let form = new FormData()
    return new Promise((resolve, reject) => {
      form = new FormData()
      form.append('email', input.email)
      form.append('password', input.password)
      http
        .post(`${this.model}/loginnow/`, form)
        .then((response: any) => {
          if (response?.code === 200) {
            accountService
              .read(response.data.user_id)
              .then((res) => {
                if (res?.data?.roles === 'normal') {
                  res.data.roles = 'users'
                  res.data.updated = false
                }
                response.data.user = { ...res.data }
                resolve(response)
              })
              .catch((err) => {
                reject(err)
              })
          } else {
            reject(response)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  initiateReset(input): Promise<any> {
    let form = new FormData()
    return new Promise((resolve, reject) => {
      form = new FormData()
      form.append('email', input.email)
      http
        .post(`${this.model}/password/reset/`, form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  confirmReset(input): Promise<any> {
    let form = new FormData()
    return new Promise((resolve, reject) => {
      form = new FormData()
      form.append('new_password1', input.new_password1)
      form.append('new_password2', input.new_password2)
      form.append('uid', input.uid)
      form.append('token', input.token)
      http
        .post(`${this.model}/password/reset/confirm/`, form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  changeHash(input): Promise<any> {
    let form = new FormData()
    return new Promise((resolve, reject) => {
      form = new FormData()
      form.append('old_password', input.old_password)
      form.append('new_password1', input.new_password1)
      form.append('new_password2', input.new_password2)
      http
        .post(`${this.model}auth/password/change/`, form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  /* End of Auth0 methods */
  setSession(authResult, redirect): void {
    const token = authResult['access_token']
    const user = {
      data: authResult['user'],
      roles: authResult['roles'],
      status: authResult['status'],
    }
    dataHandler.setUserSession(token)
    dataHandler.setUserData(user)

    if (redirect) {
      // if(router.currentRoute.path === `${redirect}`) {
      //     router.go()
      // } else {
      //     router.replace({path: `${redirect}`})
      // }
    } else {
      // if (user.board_count) {
      //     router.replace({ path: `/feed`});
      // } else {
      //     router.replace({ path: `/setup`});
      // }
    }
  }

  logout = dataHandler.clearSessionData

  isAuthenticated(): boolean {
    return (
      dataHandler.getUserData() &&
      dataHandler.getUserData() !== '' &&
      dataHandler.getUserSession() &&
      dataHandler.getUserSession() !== ''
    )
  }

  editProfile(obj): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()

      form.append('_id', obj._id)
      form.append('phone', obj.phone)
      form.append('name', obj.name)
      form.append('email', obj.email)
      form.append('upload_id', obj.upload_id)
      form.append('status', obj.status)
      form.append('user_id', obj.user_id)

      http
        .post('profile/update', form)
        .then((body) => {
          body = body['data']

          if (body['code'] === 200) {
            resolve(true)
          } else {
            reject(body)
          }
        })
        .catch((err) => reject(err))
    })
  }

  changePassword(obj): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()

      form.append('username', obj.username)
      form.append('hash_old', obj.hash_old)
      form.append('hash_new', obj.hash_new)

      http
        .post('user/change_auth', form)
        .then((body) => {
          body = body['data']

          if (body['code'] === 200) {
            resolve(true)
          } else {
            reject(body)
          }
        })
        .catch((err) => reject(err))
    })
  }

  beginReset(phone): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      form.append('phone', phone)

      http
        .post('profile/reset_begin', form)
        .then((body) => {
          body = body.data
          resolve(body)
        })
        .catch((err) => reject(err))
    })
  }

  completeReset(token, hash): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()
      form.append('token', token)
      form.append('hash', hash)

      http
        .post('profile/reset_complete', form)
        .then((body: any) => {
          body = body.data

          if (body.code === 200) {
            resolve(body)
          } else {
            body.message = `${body.message} Confirm that you entered the correct passcode sent to your phone number.`
            reject(body)
          }
        })
        .catch((err) => reject(err))
    })
  }
}
