import config from 'src/config'
// eslint-disable-next-line
const crypto = require('crypto-js')

class DataHandlerService {
  private cipher = config.cipher

  setUserSession(token): void {
    const cipherText = crypto.AES.encrypt(
      JSON.stringify(token),
      this.cipher
    ).toString()
    localStorage.setItem('ustk', cipherText)
  }

  setUserData(data): void {
    const cipherText = crypto.AES.encrypt(
      JSON.stringify(data),
      this.cipher
    ).toString()
    localStorage.setItem('udt', cipherText)
  }

  setTokenAge(minutes: number): void {
    const secs = minutes * 60 // in secs
    const data = Math.floor(new Date().getTime() / 1000) + secs // next X secs
    const cipherText = crypto.AES.encrypt(
      JSON.stringify(data),
      this.cipher
    ).toString()
    localStorage.setItem('uta', cipherText)
  }

  encrypt(data): string {
    return crypto.AES.encrypt(JSON.stringify(data), this.cipher)
      .toString()
      .replace(/\//g, '*')
      .replace(/=/g, '+*')
  }

  decrypt(data): string {
    data = data.replace(/\*/g, '/').replace(/\+*/g, '=')
    return crypto.AES.decrypt(data, this.cipher).toString(crypto.enc.Utf8)
  }

  getUserSession(): string | null {
    let ustk = localStorage.getItem('ustk')
    if (ustk) {
      ustk = crypto.AES.decrypt(ustk, this.cipher).toString(crypto.enc.Utf8)

      ustk = ustk.replace(/"/g, '')
      return ustk
    } else {
      return null
    }
  }

  getUserData(): any {
    let udt = localStorage.getItem('udt')
    if (udt) {
      udt = crypto.AES.decrypt(udt, this.cipher).toString(crypto.enc.Utf8)
      const _udt = JSON.parse(udt)
      return _udt
    } else {
      return null
    }
  }

  getTokenAge(): number {
    let uta = localStorage.getItem('uta')
    if (uta) {
      uta = crypto.AES.decrypt(uta, this.cipher).toString(crypto.enc.Utf8)
      const _uta = JSON.parse(uta)
      return _uta
    } else {
      return 0
    }
  }

  getCookie(name): Promise<any> {
    let cookieValue = null
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';')
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim()
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === name + '=') {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1))
          break
        }
      }
    }
    return cookieValue
  }

  clearSessionData(): void {
    window.localStorage.removeItem('uta')
    window.localStorage.removeItem('ustk')
    window.localStorage.removeItem('udt')
  }

  setHeader(): any {
    const header = {}
    const token = this.getUserSession()
    header['Authorization'] = 'Token ' + token
    header['Content-Type'] = 'application/json'
    return header
  }
}

export const dataHandler = new DataHandlerService()
export default DataHandlerService
