const context = process.env.REACT_APP_ENV === 'development' ? 'DEBUG' : 'PROD'
// const context = 'DEBUG'

// const config: any = {}
// if (context === 'DEBUG') {
const config = {
  // Debug configuration
  DEBUG: {
    API_ROOT: 'https://niwadev.com.ng/',
    APP_ROOT: 'localhost:3000/',
    cipher: 'archimedes',
    defaultPassword: 'floatationRule',
    mailgun: {
      DOMAIN: 'mg.niwa.gov.ng',
      API_KEY: '7ad2486fd2ce8337725bfee285fd4dba-3d0809fb-94f3e469',
    },
    twilio: {
      TWILIO_ACCOUNT_SID: 'ACa3dd9566397b29f12020bbcfd8fb3eeb',
      TWILIO_AUTH_TOKEN: 'd5047d86fa82dc102ecc7e7f66ac1f3d',
      TWILIO_PHONE_NUMBER: '+447723009221',
    },
    remita_base_url: 'remitademo.net',
    remita_api_key: '1946',
    remita_merchant_id: '2547916',
    remita_service_type_ids: {
      application: '4430731',
      surveyInspection: '4430731',
      permit: '4430731',
    },
  },
  // } else {
  // Production config. To use this configuration, uncomment it and comment out the debug configuration
  PROD: {
    API_ROOT: 'https://erisapi.niwa.gov.ng/',
    APP_ROOT: 'https://eris.niwa.gov.ng/',
    cipher: 'archimedes',
    defaultPassword: 'floatationRule',
    mailgun: {
      DOMAIN: 'mg.niwa.gov.ng',
      API_KEY: '7ad2486fd2ce8337725bfee285fd4dba-3d0809fb-94f3e469',
    },
    twilio: {
      TWILIO_ACCOUNT_SID: 'ACa3dd9566397b29f12020bbcfd8fb3eeb',
      TWILIO_AUTH_TOKEN: 'd5047d86fa82dc102ecc7e7f66ac1f3d',
      TWILIO_PHONE_NUMBER: '+447723009221',
    },
    remita_base_url: 'login.remita.net',
    remita_api_key: 'RXJKN07H',
    remita_merchant_id: '3113524387',
    remita_service_type_ids: {
      application: '504961630',
      surveyInspection: '504961630',
      permit: '504961630',
    },
  },
}

export default config[context]
