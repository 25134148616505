import React, { createContext, useReducer } from 'react'
import Reducer from './Reducer'
import ApplicationService from 'src/services/application'

export interface IApplicationStoreState {
  isLoading: boolean
  count: number
  applications: any[]
}

export interface IApplicationStoreActions {
  read_all: () => Promise<null>
  read: (id) => Promise<null>
  read_by_area_office: (area_office_id) => Promise<null>
  read_by_applicant: (applicant_id) => Promise<null>
}

const initialState: IApplicationStoreState = {
  isLoading: true,
  count: 0,
  applications: [],
}

const applicationService = new ApplicationService()

const ApplicationStore: React.FC<any> = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState)

  const actions: IApplicationStoreActions = {
    read_all: async () => {
      return new Promise((resolve, reject) => {
        applicationService
          .read_all()
          .then((res) => {
            if (res.code === 200) {
              dispatch({
                type: 'SET_ALL',
                payload: res.data,
              })
              resolve(res.data)
            } else {
              reject(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    read: async (id) => {
      return new Promise((resolve, reject) => {
        applicationService
          .read(id)
          .then((res) => {
            if (res === 200) {
              dispatch({
                type: 'SET',
                payload: res.data,
              })
              resolve(res.data)
            } else {
              reject(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    read_by_area_office: async (area_office_id) => {
      return new Promise((resolve, reject) => {
        applicationService
          .read_all()
          .then((res) => {
            if (res.code === 200) {
              dispatch({
                type: 'SET_ALL',
                payload: res.data.filter(
                  (x) => parseInt(x.area_office) === area_office_id
                ),
              })
              resolve(
                res.data.filter(
                  (x) => parseInt(x.area_office) === area_office_id
                )
              )
            } else {
              reject(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
    read_by_applicant: async (applicant_id) => {
      return new Promise((resolve, reject) => {
        applicationService
          .read_all()
          .then((res) => {
            if (res.code === 200) {
              dispatch({
                type: 'SET_ALL',
                payload: res.data.filter((x) => x.created_by === applicant_id),
              })
              resolve(res.data.filter((x) => x.created_by === applicant_id))
            } else {
              reject(res.message)
            }
          })
          .catch((err) => {
            console.log(err)
            reject(err)
          })
      })
    },
  }

  return (
    <ApplicationContext.Provider value={[state, dispatch, actions]}>
      {children}
    </ApplicationContext.Provider>
  )
}

export const ApplicationContext = createContext<
  [
    IApplicationStoreState,
    React.Dispatch<IApplicationStoreState>,
    IApplicationStoreActions
  ]
>([initialState, null, null])
export default ApplicationStore
