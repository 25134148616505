import http from './http'
import Base from './base'

export default class InvoiceService extends Base {
  constructor() {
    super('invoice')
  }

  create(input): Promise<any> {
    return new Promise((resolve, reject) => {
      const form = new FormData()

      form.append('status', 'pending')
      form.append('date', new Date().toISOString())
      // form.append('uid', '')
      form.append('amount', input.amount)
      form.append('vat', input.vat)
      form.append('transaction_charge', input.transaction_charge)
      form.append('service', input.service)
      form.append('description', input.description)
      form.append('customer_name', input.customer_name)
      form.append('customer_email', input.customer_email)
      form.append('customer_phone', input.customer_phone)
      form.append('total_amount_payable', input.total_amount_payable)
      // form.append('created_by', this.user.data.id)
      form.append('created_by', input.created_by)
      form.append('meta_detail', input.meta_detail || '-')

      http
        .post('invoice/', form)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}
