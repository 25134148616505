import React from 'react'
// nodejs library that concatenates classes
import classnames from 'classnames'

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap'

// core components
import PublicNavbar from 'src/components/Navbars/PublicNavbar'
import GenericFooter from 'src/components/Footers/GenericFooter'

// index page sections
import { Link } from 'react-router-dom'

import promoBanner from 'src/assets/img/theme/home-promo-banner.png'

const ProcessList: React.FC<{ text: any; icon?: any }> = ({ text, icon }) => {
  icon = icon ? icon : 'fa fa-asterisk'

  return (
    text && (
      <li className="py-2">
        <div className="d-flex align-items-center">
          <div>
            <Badge className="badge-circle mr-3" color="secondary">
              <i className={`fa ${icon}`} />
            </Badge>
          </div>
          <div>
            <h6 className="mb-0">{text}</h6>
          </div>
        </div>
      </li>
    )
  )
}

class Landing extends React.Component {
  state: any = {}
  render(): React.ReactElement {
    return (
      <>
        <PublicNavbar />
        <main>
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 shape-primary">
                {/* <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span /> */}
              </div>
              <Container className="py-lg-md d-flex">
                <div className="col px-0">
                  <Row>
                    <Col md="12" lg="10">
                      <h1 className="display-3 text-white">
                        National Inland Waterways Authority (NIWA){' '}
                        <span>
                          Electronic Register & Information System (ERIS)
                        </span>
                      </h1>
                      <p className="lead text-white">
                        Welcome to the Electronic Register Information System
                        (ERIS). A platform for vessel e-registration and
                        management.
                      </p>
                      <div className="btn-wrapper">
                        <Link
                          className="btn btn-info btn-icon mb-3 mb-sm-0"
                          color="info"
                          to="/user/add-vessel"
                        >
                          {/* <span className="btn-inner--icon mr-1">
                            <i className="fa fa-code" />
                          </span> */}
                          <span className="btn-inner--text">
                            Fresh application
                          </span>
                        </Link>
                        <Link
                          className="btn btn-default btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          to="/user"
                        >
                          {/* <span className="btn-inner--icon mr-1">
                            <i className="ni ni-cloud-download-95" />
                          </span> */}
                          <span className="btn-inner--text">
                            Continue registration
                          </span>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg pt-lg-0 mt--200">
            <Container>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-check-bold" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Seamless Application Process
                          </h6>
                          <p className="description mt-3">
                            Application now simplified in four easy steps. Also
                            allows multiple vessel registration with one user
                            account. 4 easy steps that are most important
                            components and features.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              e-payment
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              registration
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              survey
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              approval
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-key-25" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Secured Payment Channels
                          </h6>
                          <p className="description mt-3">
                            Payment now made easy and secured. With our secured
                            payment gateway partners you can be sure that your
                            funds are recieved. More so, certifcates issued are
                            digitally signed to checkmate counterfeits.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              e-payment
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              secured
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              fast
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="ni ni-planet" />
                          </div>
                          <h6 className="text-primary text-uppercase">
                            Up-to-date
                          </h6>
                          <p className="description mt-3">
                            Instant notifications are sent via SMS and email to
                            keep you updated on your application process. The
                            system notification feature is enabled to send
                            reminders for permit renewals.
                          </p>
                          <div>
                            <Badge color="primary" pill className="mr-1">
                              marketing
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              product
                            </Badge>
                            <Badge color="primary" pill className="mr-1">
                              launch
                            </Badge>
                          </div>
                          <Button
                            className="mt-4"
                            color="primary"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            Learn more
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={promoBanner}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-secondary shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div>
                    <h3>Registration Process</h3>
                    <p>
                      What you need to know. Follow these steps to register your
                      vessel
                    </p>
                    {/* <p>
                      In the absence of any other proof, the thumb alone would convince
                      me of God's existence. Truth is ever to be found in simplicity,
                      and not in the multiplicity and confusion of things. - Isaac Newton
                    </p> */}
                    <ul className="list-unstyled mt-5">
                      <ProcessList text="Create an account and login" />
                      <ProcessList
                        text={
                          <>
                            <span>
                              For new registration, goto `applications` in your
                              account and start a new application. Note that you
                              will be charge an application fee.{' '}
                            </span>{' '}
                            <span className="text-danger">
                              This fee does not include your license, inspection
                              and/or certificate fee.
                            </span>
                          </>
                        }
                      />
                      <ProcessList text="Make payment via available options. On succcesful payment, logon to your account to see regenerated application form." />
                      <ProcessList text="Complete the application and submit. You will receive an acknowledgement email/sms. Another email/sms notification stating inspection date and venue. This may take up to 2 working days." />
                      <ProcessList
                        text={
                          <>
                            Upon completion of inspection, a notification of
                            approval or decline of your application will be
                            communicated to you.{' '}
                            <span className="text-danger">
                              Applicable fees for certicate, license and
                              inspection shall be stated. You are to pay the
                              said amount within 2 weeks on receipt of
                              notification.
                            </span>
                          </>
                        }
                      />
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>

          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-primary shadow-lg border-0">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        We made vessel registration easier for you.
                      </h3>
                      <p className="lead text-white mt-3">
                        {/* Are you still having troubles? As a blind man has
                        no idea of colors, so have we no idea of the manner
                        by which the all-wise God perceives
                        and understands all things. */}
                        With our secured payment gateway partners you can be
                        sure that your funds are recieved. More so, certifcates
                        issued are digitally signed to checkmate counterfeits.
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href=""
                        size="lg"
                      >
                        Call Helpdesk
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>

          <section className="section section-lg bg-gradient-default section-contact-us">
            {/* SVG separator */}
            <div className="separator separator-top separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon style={{ fill: '#fff' }} points="0 100 0 0 2560 0" />
              </svg>
            </div>
            <div className="my-4"></div>
            <Container>
              <Row className="justify-content-center">
                <Col lg="8">
                  <Card className="bg-gradient-secondary shadow">
                    <CardBody className="p-lg-5">
                      <h4 className="mb-1">Want to speak to someone?</h4>
                      <p className="mt-0">
                        Still having difficulty in registering a vessel? Let
                        someone attend to you. <br />
                        Your registration is very important to us. We take it
                        seriously.
                      </p>
                      <FormGroup
                        className={classnames('mt-5', {
                          focused: this.state.nameFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-user-run" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Your name"
                            type="text"
                            onFocus={() => this.setState({ nameFocused: true })}
                            onBlur={() => this.setState({ nameFocused: false })}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.emailFocused,
                        })}
                      >
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onFocus={() =>
                              this.setState({ emailFocused: true })
                            }
                            onBlur={() =>
                              this.setState({ emailFocused: false })
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup className="mb-4">
                        <Input
                          className="form-control-alternative"
                          cols="80"
                          name="name"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        />
                      </FormGroup>
                      <div>
                        <Button
                          block
                          className="btn-round"
                          color="default"
                          size="lg"
                          type="button"
                        >
                          Send Message
                        </Button>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  style={{ fill: '#f4f5f7' }}
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
            <div className="my-4"></div>
          </section>
        </main>
        <GenericFooter />
      </>
    )
  }
}

export default Landing
