import React from 'react'
import { Spinner } from 'reactstrap'

const SuspenseLoader: React.FC = () => {
  return (
    <>
      <div className="text-center px-3 py-4">
        <Spinner />
      </div>
    </>
  )
}

export default SuspenseLoader
